import { useState, useEffect } from 'react';
import apiCaller from '../utils/api';

const useSecureLink = (login: string, caseUrl: string, org: string): [string, boolean, string] => {
    const initSecureLinkUrl = '' as string;
    const initError = '' as string;
    const [secureLinkUrl, setSecureLinkUrl] = useState(initSecureLinkUrl)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(initError);

    useEffect(() => {
        //Set loading and error states
        setLoading(true);
        setError(error);
        apiCaller.get(`/practice-cases/securelink?login=${login}&org=${org}&caseUrl=${caseUrl}`)
        .then(response => {
            setSecureLinkUrl(response.data)
            console.log("SecureLink Response:\n", response.data)
            setLoading(false)
        })
        .catch(err => {
            console.log("SecureLink Error:\n", err)
            setError(err)
            setLoading(false)
        })
    }, [login, caseUrl])
    return [ secureLinkUrl, loading, error ];
}

export default useSecureLink;